import { AxiosError } from 'axios';
import { Component, Vue } from 'vue-property-decorator';
import ErrorHandler from '@/support/ErrorHandler';
import { Options } from '@/components/mi-dialog/MiDialog';
import { DateFormatter } from '@/support/DateFormatter';
import { Rpc } from '@/models/Rpc';
import { userRoles } from '@/models/User';

@Component<Trillingstool>({})
export default class Trillingstool extends Vue {
  public $pageTitle = 'Trillingstool';

  protected isLoading = false;

  protected fromDate = new DateFormatter();

  protected toDate = new DateFormatter();

  protected magnitude = '1,0';

  protected zipCode = '';

  protected number = '';

  protected numberAddition = '';

  protected userRoles = userRoles;

  public mounted(): void {
    this.initializeBreadcrumb();
    this.fromDate.selectedDate = '2000-01-01';
    this.toDate.selectedDate = '2022-12-31';
  }

  protected initializeBreadcrumb(): void {
    this.$root.$emit('breadcrumbUpdated', {
      crumb: [{ name: 'Trillingstool' }],
    });
  }

  protected create() {
    this.isLoading = true;

    const payload = {
      signature: 'trillingstool:generate-reportless-pdf',
      body: {
        from: this.fromDate.selectedDate,
        until: this.toDate.selectedDate,
        minimum_magnitude: this.magnitude,
        postcode: this.zipCode,
        number: this.number,
        number_add: this.numberAddition,
      },
    };

    new Rpc()
      .rpcPost(payload)
      .then((response: any) => {
        this.isLoading = false;
        window.open(response.url);
      })
      .catch((error: AxiosError) => {
        this.isLoading = false;
        ErrorHandler.network(error);
      });
  }

  // Getters
  protected get dialogOptionsCreated(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Trillings pdf is aangemaakt.',
      type: 'success',
      buttons: {
        confirm: {
          text: 'Oke',
        },
      },
    };
  }

  protected get isDisabled(): boolean {
    return ! this.fromDate.selectedDate || ! this.toDate.selectedDate || ! this.magnitude || ! this.zipCode || ! this.number;
  }
}
